// @ts-nocheck
import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { GridContainer, Layout } from '@ggs/components/layout'
import Box from '@mui/material/Box'
import { useI18n } from '@ggs/gatsby/lib'
import { breakpoints, colors, rem } from '@ggs/styles'
import { ImageCta, Title } from '@ggs/components/paragraphs'
import { FilterableProductList } from '@ggs/components/ecomm'
import { Button } from '@ggs/gatsby/components/nav'
import { icons } from '@ggs/components'
import Grid from '@mui/material/Grid'
import typography from '@ggs/styles/theme/typography'

/**
 * @typedef {import('@ggs/types').ProductCard} ProductCard
 */
/**
 * @param {string} site Site name
 * @param {Object} pageContext page data
 * @param {Object} props
 * @return {JSX.Element}
 */
const ProductListing = ({ queries: { site = '' } = {}, pageContext, ...props }) => {
  const { t } = useI18n()
  const { footerCta, items: { /** @type Array<ProductCard> */ product: products } } = pageContext?.data
  const title = t('global:label.products')

  // Update title for SEO handling
  if (pageContext?.data) {
    pageContext.data.title = title
  }

  const [desktopFilters, setDesktopFilters] = useState(true)

  const toggleDesktopFilters = () => {
    setDesktopFilters(!desktopFilters)
  }

  console.log('ProductListing render', pageContext?.data)

  return (
    <Layout
      className="product-listing"
      site={site}
      pageContext={pageContext}
      sx={{
        background: colors.ultraLightBlue,
      }}
    >
      <Box
        className="product-listing__inner"
        sx={{
          boxSizing: 'border-box',
          maxWidth: breakpoints.xl,
          mt: 0,
          mx: 'auto',
          mb: 10,
          pt: {
            xs: 2,
            md: 9,
          },
          px: {
            xs: 2,
            md: 6,
          },
          '& .product-listing__top': {
            mb: {
              xs: 2,
              md: 7,
            },
          },
          '& .product-listing__title': {
            '.title__title': {
              typography: {
                xs: typography.h3,
                md: typography.largeHeading
              },
            }
          },
          '& .product-listing__filters-btn': {
            p: 0,
          },
        }}
      >
        <Grid className="product-listing__top" container>
          <Grid item xs={12} md={9}>
            <Title className="product-listing__title" title={title} style={'h1'}/>
          </Grid>
          <Grid
            item
            md={3}
            sx={{
              justifyContent: 'end',
              display: {
                xs: 'none',
                md: 'inherit',
              },
            }}
          >
            <Button
              className="product-listing__filters-btn"
              onClick={toggleDesktopFilters}
              variant={'text'}
              label={desktopFilters ? t('global:label.hideFilters') : t('global:label.showFilters')}
              endIcon={icons.Filters}
            />
          </Grid>
        </Grid>
        <FilterableProductList
          entries={products}
          desktopFilters={desktopFilters}
          toggleDesktopFilters={toggleDesktopFilters}
        />
      </Box>
      <GridContainer
        backgroundColor={colors.pageBackgroundBlue}
        fullWidthBackground
        sx={{
          '& .grid-container': {
            maxWidth: rem(breakpoints.xl),
            py: {
              xs: 6,
              md: 0,
            },
            px: {
              md: 6,
            },
          },
        }}
      >
        {footerCta && <ImageCta className="product-listing__cta" {...footerCta} />}
      </GridContainer>
    </Layout>
  )
}

export const query = graphql`
  query productListingPage($language: String!) {
    locales: allLocale(filter: { ns: { in: ["global", "ecomm"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default ProductListing
