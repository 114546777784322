import React from 'react'
import { graphql } from 'gatsby'
import { Layout, PageHeader } from '@ggs/components/layout'
import { Form } from '@ggs/components/paragraphs'
import { Component } from '@ggs/gatsby/components'

// Assets.
import './Contact.scss'

// @ts-ignore
const Contact = ({ queries: { site } = {}, ...props }) => {
  const pageContext = props?.pageContext || {}
  const { title, contactForm, content } = pageContext?.data || {}

  return (
    <Layout className="contact" site={site} pageContext={pageContext} sx={{ p: 0 }}>
      <article>
        <PageHeader title={title} />
        {contactForm && (
          <Form {...contactForm} variant={Form.variant.selectGroup} />
        )}
        <div className="contact__content">
          {content.map((/** @type {JSX.IntrinsicAttributes & { [x: string]: any; }} */ item) =>
            (item?.type ? <Component key={item.id} {...item} /> : null))
          }
        </div>
      </article>
    </Layout>
  )
}

export const query = graphql`
	query contactPage($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`

export default Contact
