// @ts-nocheck
import React, { useEffect } from 'react'
import { GridContainer, Layout } from '@ggs/components/layout'
import { useI18n } from '@ggs/gatsby/lib'
// Assets
import { colors } from '@ggs/styles'
import { graphql, navigate } from 'gatsby'
import AccountPageLayout from '@ggs/components/account/AccountDisplayPanel/AccountPageLayout'
import ACCOUNT_VIEWS from '@ggs/components/account/Enums/ACCOUNT_VIEWS'
import { useCheckoutContext } from '@ggs/components/ecomm/Checkout/CheckoutContext'
import { useSupportingLinks } from '@ggs/components/ecomm'
import { useShouldUserBeOnDashboard } from '@ggs/components/hooks/useUserPermissions'

const { CREATE } = ACCOUNT_VIEWS

/**
 * @param {Object} props
 * @return {JSX.Element}
 */
const Signup = (props) => {
  const pageContext = props?.pageContext || {}
  const { t } = useI18n()
  const title = t('account:label.signup')
  // const {
  //   data: {
  //     layoutMeta: {
  //       currentStore: { ecommEnabled },
  //     },
  //   },
  // } = pageContext
  const createAccountTCs
    = pageContext?.data?.createAccountTCs || pageContext?.data?.listing?.createAccountTCs || null
  pageContext.data.label = title

  // If the user is already logged in, they shouldn't be here.
  useShouldUserBeOnDashboard()

  return (
    <Layout
      className="signup"
      site={props?.queries?.site}
      pageContext={pageContext}
      sx={{
        background: colors.pageBackgroundBlue,
      }}
    >
      <GridContainer
        sx={{
          pb: {
            md: 10,
          },
        }}
      >
        <AccountPageLayout view={CREATE} createAccountTCs={createAccountTCs} {...props} />
      </GridContainer>
    </Layout>
  )
}

export const query = graphql`
  query signUpPage($language: String!) {
    locales: allLocale(filter: { ns: { in: ["global", "account"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Signup
