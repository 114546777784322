// @ts-nocheck
import React from 'react'
import { graphql } from 'gatsby'
import { EventsPage } from '@ggs/components/layout'
import { PageBackgroundVariant } from '@ggs/components/flare/PageBackgroundAccent'

/**
 * @param {Object} props
 * @return {JSX.Element}
 */
const EventsLandingPage = (props) => (
  <EventsPage {...props} backgroundVariant={PageBackgroundVariant.pink} />
)

export const query = graphql`
  query eventsPage($language: String!) {
    locales: allLocale(filter: { ns: { in: ["global", "events"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default EventsLandingPage
