// @ts-nocheck
import { CartImageAds, CartItemList, OrderSummary, PurchasePolicy, useSupportingLinks } from '@ggs/components/ecomm'
import { GridContainer, Layout } from '@ggs/components/layout'
import { ProductGrid, Title } from '@ggs/components/paragraphs'
import { useI18n } from '@ggs/gatsby/lib'
import { useSelector } from '@ggs/store'
// Assets
import { colors, typography } from '@ggs/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { graphql, navigate } from 'gatsby'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { useReportCart } from './useReportCart'

/**
 * @param {Object} props
 * @return {JSX.Element}
 */
const Cart = (props) => {
  const pageContext = props?.pageContext || {}
  const { t } = useI18n()
  const order = useSelector((state) => state.commerce.order)
  const { productListing } = useSupportingLinks()
  useReportCart({ order })

  const cartIsEmpty = !order || !Array.isArray(order.line_items) || isEmpty(order.line_items)

  useEffect(() => {
    if (cartIsEmpty) {
      // dispatch(resetCheckoutState())
      navigate(productListing)
    }
  }, [cartIsEmpty, order, order?.line_items, productListing])

  const {
    data: {
      layoutMeta: {
        currentStore: { ecommEnabled },
      },
    },
  } = pageContext

  const title = t('ecomm:cart.title.shoppingCart')
  const { cartDisclaimer, cartCtaImage, cartFeaturedProducts } = pageContext?.data || {}
  pageContext.data.title = title
  return (
    <Layout
      className="cart"
      site={props?.queries?.site}
      pageContext={pageContext}
      sx={{
        background: colors.pageBackgroundBlue,
      }}
    >
      <GridContainer
        sx={{
          pb: {
            xs: 2,
            md: 10,
          },
          pt: {
            md: 9,
          },
        }}
      >
        <Box
          className="cart__content"
          sx={{
            '> .grid-container > div': {
              px: 0,
            },
          }}
        >
          <Box
            className="cart__title"
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: {
                xs: 3,
                md: 7,
              },
              '& .title': {
                mr: {
                  xs: 1,
                  md: 3,
                },
                '& .title__title': {
                  h1: {
                    typography: {
                      xs: typography.h3,
                      md: typography.largeHeading,
                    },
                  },
                },
              },
            }}
          >
            <Title title={title} style="h1" />
            {!cartIsEmpty && (
              <span>
                {order?.skus_count} {t('ecomm:cart.label.items')}
              </span>
            )}
          </Box>
          {ecommEnabled ? (
            <Grid container spacing={{ xs: 0, md: 4 }}>
              <Grid item xs={12} lg={8}>
                <CartItemList order={order} />
              </Grid>
              <Grid item xs={12} lg={4}>
                <OrderSummary order={order} variant={'cart'} />
                <PurchasePolicy disclaimer={cartDisclaimer} />
                <CartImageAds cartCtaImage={cartCtaImage} />
              </Grid>
            </Grid>
          ) : (
            t('ecomm:notice.ecommDisabled')
          )}
        </Box>
      </GridContainer>
      <ProductGrid
        className="cart__related"
        relatedProducts={cartFeaturedProducts}
        actionLabel={t('ecomm:product.button.buyNow')}
        container={{
          fullWidthBackground: true,
          backgroundColor: colors.ultraLightBlue,
        }}
        type={'Cart-ProductGrid'}
        id={'Cart-ProductGrid'}
      />
    </Layout>
  )
}

export const query = graphql`
  query cartPage($language: String!) {
    locales: allLocale(filter: { ns: { in: ["global", "ecomm"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Cart
