// @ts-nocheck
import React from 'react'
import { Layout } from '@ggs/components/layout'
import { useI18n } from '@ggs/gatsby/lib'
// Assets
import { colors } from '@ggs/styles/muiTheme'
import { graphql } from 'gatsby'
import AccountPageLayout from '@ggs/components/account/AccountDisplayPanel/AccountPageLayout'
import ACCOUNT_VIEWS from '@ggs/components/account/Enums/ACCOUNT_VIEWS'
import { useShouldUserBeOnDashboard } from '@ggs/components/hooks/useUserPermissions'

const { LOGIN } = ACCOUNT_VIEWS

/**
 * @param {Object} props
 * @return {JSX.Element}
 */
const Login = (props) => {
  const pageContext = props?.pageContext || {}
  const { t } = useI18n()
  const title = t('account:label.signup')
  const {
    data: {
      layoutMeta: {
        currentStore: { ecommEnabled },
      },
    },
  } = pageContext
  pageContext.data.label = title
  pageContext.data.title = title

  // If the user is already logged in, they shouldn't be here.
  useShouldUserBeOnDashboard()

  return (
    <Layout
      className="signup"
      site={props?.queries?.site}
      pageContext={pageContext}
      sx={{
        background: colors.pageBackgroundBlue,
      }}
    >
      <AccountPageLayout view={LOGIN} />
    </Layout>
  )
}

export const query = graphql`
  query signInPage($language: String!) {
    locales: allLocale(filter: { ns: { in: ["global", "account"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Login
