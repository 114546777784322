// @ts-nocheck
import React from 'react'
import { graphql } from 'gatsby'
import { Homepage } from '../layouts'

/**
 * Handle dynamic homepage redirect based on auto detected user location preferred store.
 * @param {Object} props
 * @return {JSX.Element}
 */
function Index(props) {
  return (
    <Homepage {...props} />
  )
}

export const query = graphql`
  query fallbackHomePage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Index
