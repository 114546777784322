import React from 'react'
import { graphql } from 'gatsby'
import { FaqCategories, Layout } from '@ggs/components/layout'

// Assets.
import './FaqIndex.scss'
import { getSearchParams } from '@ggs/utils'

/**
 * @typedef {import('@ggs/types').FaqCategory} FaqCategory
 * @typedef {import('@ggs/types').Faq} Faq
 * @typedef {import('@ggs/types').SharedContent} SharedContent
 */

/**
 * Faq layout.
 * @return {JSX.Element}
 */
const FaqIndex = ({ queries: { site = null } = {}, ...props }) => {
  const pageContext = props?.pageContext || {}
  const { title, subtitle, faqCategories } = pageContext?.data || {}
  const qs = getSearchParams()
  const getSearchQuery = qs?.get('search')
  return (
    <Layout className="faq" site={site} pageContext={pageContext} sx={{ p: 0 }}>
      <FaqCategories
        title={title}
        subtitle={subtitle}
        faqCategories={faqCategories}
        getSearchQuery={getSearchQuery}
      />
    </Layout>
  )
}

export const query = graphql`
  query faqPage($language: String!) {
    locales: allLocale(filter: { ns: { in: ["global", "faqs"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default FaqIndex
