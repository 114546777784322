// @ts-nocheck
import React from 'react'
import { Layout } from '@ggs/components/layout'
import { useI18n } from '@ggs/gatsby/lib'
// Assets
import { colors } from '@ggs/styles/muiTheme'
import { graphql } from 'gatsby'
import AccountPageLayout from '@ggs/components/account/AccountDisplayPanel/AccountPageLayout'
import ACCOUNT_VIEWS from '@ggs/components/account/Enums/ACCOUNT_VIEWS'

const { FORGOT_PASSWORD } = ACCOUNT_VIEWS

/**
 * @param {Object} props
 * @return {JSX.Element}
 */
const ForgotPassword = (props) => {
  const pageContext = props?.pageContext || {}
  const { t } = useI18n()
  const title = t('account:label.signup')
  const {
    data: {
      layoutMeta: {
        currentStore: { ecommEnabled },
      },
    },
  } = pageContext
  const data = pageContext.data
  data.label = title
  data.title = title

  return (
    <Layout
      className="signup"
      site={props?.queries?.site}
      pageContext={pageContext}
      sx={{
        background: colors.pageBackgroundBlue,
      }}
    >
      <AccountPageLayout view={data?._viewPage || FORGOT_PASSWORD} override={data?._override} {...data}/>
    </Layout>
  )
}

export const query = graphql`
  query forgotPasswordPage($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["global", "account"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default ForgotPassword
