// @ts-nocheck
import React, { useEffect } from 'react'
import { GridContainer, Layout, StackedContent } from '@ggs/components/layout'
import { graphql } from 'gatsby'
import { useI18n, useLocalizedList } from '@ggs/gatsby/lib'
import { ArticleHero } from '@ggs/components/banners'
import { CardGrid } from '@ggs/components/grids'
import { PageBackgroundAccent } from '@ggs/components/flare'
import { ProductGrid } from '@ggs/components/paragraphs'
// Assets
import './Article.scss'
import { colors } from '@ggs/styles'
import { get } from 'lodash'
import { transformMetaData } from '@ggs/transform/meta'
import { getWindow } from '@ggs/utils'
import { PageBackgroundVariant } from '@ggs/components/flare/PageBackgroundAccent'
import { useBackToLink } from '../useBackToLink'

/**
 * @typedef {import('@ggs/types').Article} Article
 */

const Article = ({ queries: { site } = {}, ...props }) => {
  const { t } = useI18n()
  const pageContext = props?.pageContext || {}
  const { content, relatedProducts, layoutMeta, /** @type Article */ ...data }
    = pageContext?.data || {}
  const href = getWindow('location.href')
  const relatedArticles = useLocalizedList(pageContext?.data?.relatedArticles)
  const { BackToNews } = useBackToLink()

  useEffect(() => {
    // Update meta data for SEO
    const metaImageSrc = get(
      data,
      'articleImage.styles.pageHeroFeatured.childImageSharp.gatsbyImageData.images.fallback.src'
    )
    transformMetaData(pageContext, {
      image_src: metaImageSrc,
      'og:image': metaImageSrc,
      graphData: {
        '["@graph"][0].image.url': metaImageSrc,
        '["@graph"][0].mainEntityOfPage': href,
        '["@graph"][0].about': data?.articleCategories?.map((c) => c.name)?.join(', '),
      },
    })
  }, [])

  console.log('Article render', pageContext)

  return (
    <Layout
      className="article"
      site={site}
      pageContext={pageContext}
      backgroundVariant={PageBackgroundVariant.blue}
    >
      <article>
        <GridContainer
          sx={{
            py: '0 !important',
          }}
        >
          <PageBackgroundAccent variant={PageBackgroundVariant.blue} />
          <BackToNews />
        </GridContainer>
        <GridContainer className="article__content">
          <ArticleHero {...data} />
          <StackedContent content={content} maxWidth={856} />
        </GridContainer>
        <CardGrid
          className="article__related"
          title={t('global:label.moreLikeThis')}
          items={relatedArticles}
          useDivider
          container={{ fullWidthBackground: true, backgroundColor: colors.ultraLightBlue }}
          component={CardGrid.component.ArticleCard}
        />
        <ProductGrid
          className="article__products"
          relatedProducts={relatedProducts}
          container={{ fullWidthBackground: true, backgroundColor: colors.ultraLightBlue }}
          component={CardGrid.component.ProductCard}
          type={'Article-ProductGrid'}
          id={data?.entityId ?? data?.id}
        />
      </article>
    </Layout>
  )
}

export const query = graphql`
  query articlePage($language: String!) {
    locales: allLocale(filter: { ns: { in: ["global", "news"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Article
