// @ts-nocheck
import React, { useRef } from 'react'
import { graphql } from 'gatsby'
import { GridContainer, Layout, PageHeader } from '@ggs/components/layout'
import { TextCard, Title } from '@ggs/components/paragraphs'
import Grid from '@mui/material/Grid'
import { Component } from '@ggs/gatsby/components'
import { useI18n } from '@ggs/gatsby/lib'
import { uniqueId } from 'lodash'
import { ImageStyles } from '@ggs/gatsby/components/media'
import { colors } from '@ggs/styles'

import './LungHealthLandingPage.scss'
import { useMatchHeight } from '@ggs/hooks'

/**
 * @param {Object} props
 * @return {JSX.Element}
 */
const LungHealthLandingPage = ({ queries: { site = '' } = {}, pageContext, ...props }) => {
  const { t } = useI18n()
  const data = pageContext?.data || {}
  const { title = '', subtitle = '', multiStepPages = [] } = data
  const content = data?.content || []
  console.log('LungHealthLandingPageData: ', data)

  const itemsRef = useRef()
  // @ts-ignore
  useMatchHeight({
    ref: itemsRef,
    resultList: multiStepPages.length > 0,
    target: '.MuiCard-root',
    stateChange: multiStepPages,
  })

  return (
    <Layout
      className="lung-health-landing"
      site={site}
      pageContext={pageContext}
      sx={{
        background: colors.smokeBlue,
      }}
    >
      <PageHeader title={title} subtitle={subtitle} />
      <GridContainer
        sx={{
          '.multiStepPages .text-card': {
            maxWidth: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'flex-start',
          },
          '.text-card__actions': {
            ml: {
              lg: 2,
            },
          },
          '.multiStepPages__title': {
            textAlign: 'center',
            mt: 3,
            mb: 2,
          },
        }}
      >
        <Grid className={'multiStepPages'} containerpadding={0}>
          <Grid className={'multiStepPages__title'} item xs={12} sx={{ mb: 5 }}>
            <Title title={t('global:label.livingWithRespiratoryConditions')} />
          </Grid>
          <Grid item xs={12}>
            <Grid container ref={itemsRef} spacing={5}>
              {multiStepPages.map((item) => (
                <Grid item xs={12} md={6} key={uniqueId(`step-item_${item.id}`)}>
                  <TextCard
                    label={item.title}
                    link={{ uri: item.url, title: t('global:button.learnMore') }}
                    textContent={item.listingDescription}
                    image={<ImageStyles {...item.listingImage} selectedStyles={['productCard']} />}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        {content.map((item) => (
          <Component key={uniqueId(`content-item_${item.id}`)} {...item} type={item.type} />
        ))}
      </GridContainer>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["global", "lung_health"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default LungHealthLandingPage
