// @ts-nocheck
import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { typography, zIndex } from '@ggs/styles'
import { BackLink } from '@ggs/components/nav'
import { Title } from '@ggs/components/paragraphs'
import useAccountViewTitleTrans from '@ggs/components/account/Hooks/useAccountViewTitleTrans'
import ACCOUNT_VIEWS from '../Enums/ACCOUNT_VIEWS'
import { useSupportingLinks } from '@ggs/components/ecomm'

export const TitleBox = ({ selectedView, setSelectedView }) => {
  const { t } = useTranslation()
  const siteLinks = useSupportingLinks()
  const viewTitle = useAccountViewTitleTrans()[selectedView]
  const BACK_TO_DASHBOARD = t('account:button.backToDashboard')
  const BACK_TO_SIGN_IN = t('account:button.backToSignIn')
  /**
     * @type {{ [x: string]: { backLinkTitle: string; backLinkUri: string; }; }}
     */
  const ACCOUNT_BACK_LINKS = {
    [ACCOUNT_VIEWS.CREATE]: {
      backLinkTitle: '',
      backLinkUri: null
    },
    [ACCOUNT_VIEWS.LOGIN]: {
      backLinkTitle: '',
      backLinkUri: null
    },
    [ACCOUNT_VIEWS.FORGOT_PASSWORD]: {
      backLinkTitle: BACK_TO_SIGN_IN,
      backLinkUri: siteLinks.login
    },
    [ACCOUNT_VIEWS.RESET_PASSWORD]: {
      backLinkTitle: BACK_TO_SIGN_IN,
      backLinkUri: siteLinks.login
    },
    [ACCOUNT_VIEWS.ORDER]: {
      backLinkTitle: BACK_TO_DASHBOARD,
      backLinkUri: siteLinks.account
    },
    [ACCOUNT_VIEWS.HISTORY]: {
      backLinkTitle: BACK_TO_DASHBOARD,
      backLinkUri: siteLinks.account
    },
    [ACCOUNT_VIEWS.DASHBOARD]: {
      backLinkTitle: '',
      backLinkUri: null
    },
    [ACCOUNT_VIEWS.EDIT]: {
      backLinkTitle: BACK_TO_DASHBOARD,
      backLinkUri: siteLinks.account
    },
    [ACCOUNT_VIEWS.ADDRESSES]: {
      backLinkTitle: BACK_TO_DASHBOARD,
      backLinkUri: siteLinks.account
    },
  }

  const backLink = t(ACCOUNT_BACK_LINKS[selectedView])
  const { backLinkUri, backLinkTitle } = backLink
  console.log('TitleBox render', selectedView, backLink)
  return (
    <Grid container spacing={2} padding={2}>
      <Grid item xs={12} md={12}>
        <Box
          className="signup__title"
          sx={{
            display: 'flex',
            alignItems: 'center',
            pb: {
              md: 10,
            },
            // mb: {
            //   xs: 3,
            //   md: 7,
            // },
            '.back-link': {
              zIndex: zIndex.zOverlay,
              // p: 0,
              pl: {
                xs: 2,
                md: 5,
              },
            },
            '> .grid-container > div': {
              px: 0,
            },
            '& .divider': {
              px: 0,
            },
            '& .title': {
              mx: 'auto',
              '& .title__title': {
                'h1': {
                  typography: {
                    xs: typography.h3,
                    md: typography.largeHeading,
                  }
                }
              }
            },
          }}
        >
          {backLinkTitle ? <BackLink label={backLinkTitle} onClick={() => {
            setSelectedView(backLinkUri)
          }}/> : null}
          <Title title={viewTitle} className={'.title__title'} style="h1"/>
        </Box>
      </Grid>
    </Grid>
  )
}
