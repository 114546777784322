const { forEach, get, find, set, size } = require('lodash')
const getWindow = require('../../utils/getWindow')

/**
 *
 * @param {{data: Object}} pageContext
 * @param {Object} replacements
 */
const transformMetaData = ({ data }, replacements) => {
  const { metaTags } = data
  const schema = JSON.parse(data.metaSchema)
  const href = getWindow('location.href')

  const { graphData, ...overrides } = replacements || {}
  Object.assign(overrides,{
    'og:locale': data.layoutMeta.lang.replace('-', '_'),
  })

  if (size(schema)) {
    // Add some defaults
    Object.assign(graphData, {
      '["@graph"][0].url': href,
    })

    forEach(graphData, (value, key) => {
      // If this is a graph override, apply to schema
      set(schema, key, value)
      // console.log(
      //   `transformMetaData ===SCHEMA===\nKey: ${key}\nResulting value ${get(schema, key)}`
      // )
    })
  }

  forEach(overrides, (value, key) => {
    // Else, apply to metaTags
    const tag = find(metaTags, ['key', key])
    if (tag) {
      // If tag exists, override value.
      tag.value = value
      // console.log(`transformMetaData ===TAG===\nKey: ${key}\nResulting value ${tag.value}`)
    } else {
      // Else, add the tag and value.
      metaTags.push({ key, value })
    }
  })

  // Finally, replace metaSchema with the revised schema
  data.metaSchema = JSON.stringify(schema)

  // console.log('transformMetaData', { metaTags, schema, data })
}

module.exports = transformMetaData
