import React, { useMemo } from 'react'
import { GridContainer, Layout, PageHeader } from '@ggs/components/layout'
import { graphql } from 'gatsby'
import { Component } from '@ggs/gatsby/components'
import { Carousel } from '@ggs/components/banners'
// Assets
import './Page.scss'
import { HcpGatedModal } from '@ggs/components/banners'

/**
 * Layout sizes
 * @enum {string}
 */
const LAYOUT_SIZES = {
  default: 'default',
  condensed: 'condensed',
  full: 'full',
}

// @ts-ignore
const Page = ({ queries: { site } = {}, ...props }) => {
  const pageContext = props?.pageContext || {}
  const {
    pageTitle,
    subtitle,
    content,
    layoutSize = LAYOUT_SIZES.default,
    pageLeveragesHcpGating = false,
  } = pageContext?.data || {}


  const carouselItems = pageContext?.data?.content
    .filter((item) => item.type === 'Carousel')

  const sx = useMemo(() => {
    const styles = {}
    switch (layoutSize) {
      case LAYOUT_SIZES.condensed:
        Object.assign(styles, {
          width: { md: '856px' },
        })
        break
      case LAYOUT_SIZES.full:
        Object.assign(styles, {
          p: 0,
          maxWidth: '100%',
        })
        break
    }
    return styles
  }, [layoutSize])

  console.log('Page render', pageContext?.data)

  return (
    <Layout className="page" site={site} pageContext={pageContext} sx={{
      p: 0,
      '.faq-category': {
        backgroundColor: 'transparent',
        px: 0,
      },
    }}>
      <>
        {pageTitle && <PageHeader title={pageTitle} subtitle={subtitle} />}
        <GridContainer sx={sx}>
          {content.map((/** @type {JSX.IntrinsicAttributes & { [x: string]: any; }} */ item) => {
            if (item?.type) {
              if (item.type === 'Carousel') {
                // Map carousel items for the current carousel
                const carouselPageHeroes = item.carouselItem.map((hero) => ({
                  ...hero,
                  variant: 'carousel',
                }))
                return (
                  <Carousel className={'page-heroes'} key={item.id} carouselItems={carouselPageHeroes} />
                )
              }

              return <Component key={item.id} {...item} />
            }

            return null
          })}
        </GridContainer>
        <HcpGatedModal requireHcpGating={pageLeveragesHcpGating}
          hcpGatedModal={pageContext?.data?.layoutMeta?.currentStore?.hcpGatedModal} />
      </>
    </Layout>
  )
}

export const query = graphql`
  query simplePage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Page
