// @ts-nocheck
import React, { Suspense } from 'react'
import { graphql } from 'gatsby'
import { Layout } from '@ggs/components/layout'
import Box from '@mui/material/Box'
import { breakpoints, rem } from '@ggs/styles'
import { Carousel } from '@ggs/components/banners'
import colors from '@ggs/styles/theme/colors'
import { PageBackgroundVariant } from '@ggs/components/flare/PageBackgroundAccent'
import HomepageProductIntro from './HomepageProductIntro'
import { HomepageStackedContent } from './HomepageStackedContent'

const HomepageNewsEvents = React.lazy(() => import('./HomepageNewsEvents'))

/**
 * @typedef {import('@ggs/types').ProductCard} ProductCard
 */
/**
 * @param {string} site Site name
 * @param {Object} pageContext page data
 * @param {Object} props
 * @return {JSX.Element}
 */
const Homepage = ({ queries: { site = '' } = {}, pageContext, ...props }) => {
  const {
    featuredProducts = null,
    testimonialsTitle = null,
    featuredTestimonials = null,
    highlightCtas = null,
  } = pageContext?.data || {}
  const carouselHeroes = pageContext?.data?.carouselHeroes?.map((hero) => ({
    ...hero,
    variant: 'carousel',
  }))

  const content = pageContext?.data?.content
  const { productIntroDescription = null, productIntroCtas = null } = pageContext?.data || {}

  const pageSx = {
    '.homepage-heroes, .product-grid': {
      backgroundColor: colors.ultraLightBlue,
    },
    '.highlight-cta': {
      backgroundColor: colors.white,
    },
    '.grid-container': {
      maxWidth: '100%',
      '.grid-container-inner': {
        maxWidth: rem(breakpoints.xl),
        '.grid-container, .grid-container-inner': {
          maxWidth: '100%',
          m: 0,
          p: 0,
        },
      },
    },
  }

  return (
    <Layout
      className="homepage"
      site={site}
      pageContext={pageContext}
      backgroundVariant={PageBackgroundVariant.blue}
    >
      <Box sx={pageSx}>
        <Carousel className={'homepage-heroes'} carouselItems={carouselHeroes} />
        {Array.isArray(productIntroCtas) && productIntroCtas.length ? (
          <HomepageProductIntro
            productIntroDescription={productIntroDescription}
            productIntroCtas={productIntroCtas}
          />
        ) : null}
        <HomepageStackedContent
          content={[
            ...(Array.isArray(content) ? content : []),
            // deprecated
            featuredProducts,
            testimonialsTitle,
            featuredTestimonials,
            highlightCtas,
          ]}
          onUk={true}
        />
        <Suspense fallback={null}>
          <HomepageNewsEvents {...pageContext} />
        </Suspense>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query homePage($language: String!) {
    locales: allLocale(filter: { ns: { in: ["global", "events"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Homepage
