// @ts-nocheck
import React from 'react'
import Box from '@mui/material/Box'
import { GridContainer } from '@ggs/components/layout'
import './accountDisplayPanel.scss'
import { useAccountViewPermissions } from '@ggs/components/account/Hooks/API/useAccountViewPermissions'
import { AccountView } from '@ggs/components/account/AccountView'
import { TitleBox } from '@ggs/components/account/AccountDisplayPanel/TitleBox'
import ACCOUNT_VIEWS from '../Enums/ACCOUNT_VIEWS'
const { CREATE } = ACCOUNT_VIEWS

/**
 * Create account / login select.
 * @return {JSX.Element}
 */
export default function AccountPageLayout({ view = CREATE, ...props }) {
  const { displayView, setViewIfAllowed } = useAccountViewPermissions({ view, override: props?.override || false })
  return (
    <GridContainer
      sx={{
        pb: {
          md: 10,
        },
      }}
    >
      <Box
        className="signup__content"
        sx={{
          '> .grid-container > div': {
            px: 0,
          },
          '& .divider': {
            px: 0
          }
        }}
      >
        <TitleBox selectedView={displayView} setSelectedView={setViewIfAllowed}/>
        <AccountView view={displayView} setSelectedView={setViewIfAllowed} {...props} />
      </Box>
    </GridContainer>
  )
}
