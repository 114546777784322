import { useEffect, useState } from 'react'
import { useCheckoutAnalytics } from '@ggs/components/ecommerceAnalytics/hooks/useCheckoutAnalytics'
import { compareItems } from '@ggs/components/ecommerceAnalytics/gtm/commerceToGTM/mapItemsToGTM'
import { gtmItems } from '@ggs/components/ecommerceAnalytics/gtm/commerceToGTM/gtagFromOrderParse'

export const useReportCart = ({ order }) => {
  const [reportedCart, setReportedCart] = useState([])
  const { fireViewCart } = useCheckoutAnalytics({ order })

  useEffect(() => {
    setReportedCart((currentValue) => {
      if (!compareItems(reportedCart, gtmItems(order))) {
        fireViewCart({ order })
        return gtmItems(order)
      } else {
        return currentValue
      }
    })
  }, [order])

  return {
    reportedCart,
    setReportedCart,
  }
}