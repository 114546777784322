// @ts-nocheck
import React from 'react'
import { graphql } from 'gatsby'
import { StorybookRootProviders } from '@ggs/gatsby/store'

const StorybookPage = ({ children }) => {
  return (
    <StorybookRootProviders>
      <div
        dangerouslySetInnerHTML={{
          __html: `<style>
        .sb-show-main {
            background-color: #fff !important;
            padding: 0 !important;
        }
        .MuiContainer-maxWidthXl {
          max-width: 1304px;
        } 
        [data-addon-id="storybook-addon-grid"] > div > div {
         opacity: .5;
        }
        /*[data-addon-id="storybook-addon-grid"] > div {*/
        /* grid-template-columns: repeat(12, 72px) !important;*/
        /*}*/
      </style>`,
        }}
      />
      {children}
    </StorybookRootProviders>
  )
}

export const query = graphql`
  query storybookPage($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default StorybookPage
