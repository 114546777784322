// @ts-nocheck
import React, { useEffect, useMemo } from 'react'
import { find, get } from 'lodash'
import dayjs from 'dayjs'
import { graphql } from 'gatsby'
import { useI18n } from '@ggs/gatsby/lib'
import { transformMetaData } from '@ggs/transform/meta'
import { GridContainer, Layout, StackedContent } from '@ggs/components/layout'
import { CardGrid } from '@ggs/components/grids'
import { PageBackgroundAccent } from '@ggs/components/flare'
import { EventHero } from '@ggs/components/banners'
import { ProductGrid } from '@ggs/components/paragraphs'
// Assets
import '../Article/Article.scss'
import { colors } from '@ggs/styles'
import { useBackToLink } from '../useBackToLink'

const Event = ({ queries: { site } = {}, ...props }) => {
  const { t } = useI18n()
  const pageContext = props?.pageContext || {}
  const { relatedProducts, layoutMeta, ...data } = pageContext?.data || {}
  const { BackToEvents } = useBackToLink()

  useEffect(() => {
    // Update meta data for SEO
    const metaImageSrc = get(
      data,
      'eventImage.styles.pageHeroFeatured.childImageSharp.gatsbyImageData.images.fallback.src'
    )
    transformMetaData(pageContext, {
      image_src: metaImageSrc,
      'og:image': metaImageSrc,
      graphData: {
        '["@graph"][0].image.url': metaImageSrc,
        '["@graph"][0].startDate': dayjs.unix(data?.eventDate?.start).format(),
        '["@graph"][0].endDate': dayjs.unix(data?.eventDate?.end).format(),
        '["@graph"][0].category': data?.eventCategories?.map((c) => c.name)?.join(', '),
      },
    })
  }, [])

  const content = useMemo(() => {
    const c = pageContext?.data?.content || []
    const id = 'eventSummary'

    // Do not duplicate the summary.
    if (find(c, ['id', id])) {
      return c
    }
    // Place the summary at the top of the page, after the hero.
    c.unshift({
      id,
      type: 'Text',
      textContent: pageContext?.data?.summary,
    })

    console.log('adding summary to content', c)

    return c
  }, [pageContext?.data?.summary])

  // console.log('Event render', pageContext, listing)

  return (
    <Layout
      className="article"
      site={site}
      pageContext={pageContext}
      backgroundVariant={PageBackgroundAccent.variant.pink}
    >
      <article>
        <GridContainer
          sx={{
            py: '0 !important',
          }}
        >
          <PageBackgroundAccent variant={PageBackgroundAccent.variant.pink} />
          <BackToEvents />
        </GridContainer>
        <GridContainer className="article__content">
          <EventHero {...data} />
          <StackedContent content={content} maxWidth={856} />
        </GridContainer>
        <ProductGrid
          className="article__products"
          relatedProducts={relatedProducts}
          container={{ fullWidthBackground: true, backgroundColor: colors.ultraLightBlue }}
          component={CardGrid.component.ProductCard}
          type={'Event-ProductGrid'}
          id={data?.entityId ?? data?.id}
        />
      </article>
    </Layout>
  )
}

export const query = graphql`
  query eventPage($language: String!) {
    locales: allLocale(filter: { ns: { in: ["global", "events"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Event
