// @ts-nocheck
import React from 'react'
import { GridContainer } from '@ggs/components/layout'
import { Component } from '@ggs/gatsby/components'
import Grid from '@mui/material/Grid'

// Assets
import './HomepageProductIntro.scss'

const HomePageProductItems = ({ productIntroCtas }) =>
  <Grid className={'homepage-product-intro__items'}
    container
    spacing={{ xs: 2, md: 5 }}
  >
    {productIntroCtas.map((cta) => (
      <Grid className={'homepage-product-intro__item'} item key={cta.id} xs={6}>
        <Component {...cta} />
      </Grid>
    ))}
  </Grid>

const HomePageProductItemsIntro = ({ productIntroDescription, productIntroCtas }) =>
  <Grid container spacing={5} justifyContent={'space-between'}>
    <Grid item xs={12} md={5} alignItems={'center'} display={'inline-flex'}>
      <Component {...productIntroDescription} />
    </Grid>
    <Grid item xs={12} md={7} lg={6}>
      <HomePageProductItems productIntroCtas={productIntroCtas}/>
    </Grid>
  </Grid>

const HomepageProductIntro = ({ productIntroCtas = null, productIntroDescription = [] }) =>
  <GridContainer
    className={'homepage-product-intro'}
    sx={{
      my: {
        xs: 7,
        md: 12,
      },
      py: '0 !important',
      '.text-card, .text-card .grid-container__inner': {
        p: 0,
        m: 0,
      },
      '.text-card__content': {
        '&, *': {
          textAlign: 'center',
        },
        img: {
          maxWidth: '100%',
          height: 'auto',
        },
      },
    }}
  >
    {productIntroDescription
      ? <HomePageProductItemsIntro productIntroCtas={productIntroCtas}
        productIntroDescription={productIntroDescription}/>
      : <HomePageProductItems productIntroCtas={productIntroCtas}/>
    }
  </GridContainer>

export default HomepageProductIntro
